import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Script from "next/script";

import { Player } from "@lottiefiles/react-lottie-player";
import {
  RhButton,
  RhDivider,
  RhDrawer,
  RhIcon,
  RhInput,
  RhListContainer,
  RhListItem,
} from "@rhythm-ui/react";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE, USER_ROLE } from "@/constants";
import {
  animationStartHereArrow,
  employerCandidateCard,
  iconBriefcase,
  iconDocumentOutlined,
  iconTwoPesonOutlined,
  vectorSquares,
  zelevateWordLogoWhiteText,
} from "@/constants/media";

import CustomIcon from "@/components/utils/CustomIcon";

const Banner = () => {
  const router = useRouter();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const onScroll = useCallback(() => {
    setScrollY(window.pageYOffset);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsHighlighted(true);
    }, 700);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      return window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onEmployerEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.target.email.value;
    localStorage.setItem(LOCAL_STORAGE.EMPLOYER_SIGNUP_EMAIL, email);
    router.push("/request-demo");
  };

  const [isCardAnimationComplete, setIsCardAnimationComplete] = useState(false);
  const onCandidateCardAnimationComplete = () => {
    setIsCardAnimationComplete(true);
  };

  const onLoginAsEmployerHandler = () => {
    localStorage.setItem(LOCAL_STORAGE.LAST_LOGIN_USER_TYPE, USER_ROLE.CLIENT);
    router.push("/login");
  };

  return (
    <>
      <Script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></Script>

      <div className="relative overflow-clip border border-b-0 border-l-0 border-r-0 border-[#1B2434] bg-[#1B2434]">
        <nav
          className={classNames(
            "zelevate-container fixed top-0 z-[8] flex h-20 w-full shrink-0 items-center justify-center text-lg",
            {
              "bg-[#1B2434]": scrollY > 20,
            },
          )}
        >
          <RhDrawer
            isOpen={isDrawerOpen}
            variant="temporary"
            className=""
            backdrop={true}
            position="right"
            closeOnOutsideClick={true}
          >
            <div className="flex h-full flex-col justify-between bg-[#1B2434]">
              <div>
                <div className="flex items-center justify-between p-5">
                  <div
                    className="flex w-2/3 cursor-pointer"
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    <Link href="/" passHref>
                      <Image
                        src={zelevateWordLogoWhiteText}
                        width="144"
                        height="48"
                        alt="logo"
                        className="cursor-pointer"
                        layout="fixed"
                      />
                    </Link>
                  </div>

                  <RhIcon
                    icon={"material-symbols:close-rounded"}
                    className="cursor-pointer text-2xl text-white"
                    onClick={() => setIsDrawerOpen(false)}
                  />
                </div>

                <RhDivider width="sm" />

                <div className="flex flex-col gap-3">
                  <RhListContainer>
                    <RhListItem>
                      <Link href="/job-seeker" passHref>
                        <RhButton className="w-full text-white" layout="link">
                          Job Seeker
                        </RhButton>
                      </Link>
                    </RhListItem>

                    <RhListItem className="mb-tsm">
                      <Link href="/login" passHref>
                        <RhButton
                          className="w-full text-white"
                          layout="outline"
                        >
                          Login
                        </RhButton>
                      </Link>
                    </RhListItem>
                    <RhListItem>
                      <Link href={"/request-demo"}>
                        <RhButton className="btn-bg w-full">
                          Book A Demo
                        </RhButton>
                      </Link>
                    </RhListItem>
                  </RhListContainer>
                </div>
              </div>
            </div>
          </RhDrawer>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-36 cursor-pointer">
              <Link href="/">
                <Image
                  src={zelevateWordLogoWhiteText}
                  className="m-0 cursor-pointer p-0 text-white"
                  alt="logo"
                  width="165"
                  height="56"
                />
              </Link>
            </div>
            <div className="flex items-center justify-end gap-4 lg:basis-[70%]">
              <div className="hidden items-center justify-center gap-8 text-base font-normal lg:flex">
                <>
                  {/* <Link href="/product" passHref>
                  <a className="no-underline  text-white">
                    <span>Product</span>
                  </a>
                </Link>
                <Link href="/pricing" passHref>
                  <a className="no-underline text-white">
                    <span>Pricing</span>
                  </a>
                </Link> */}

                  <Link href="/job-seeker" passHref>
                    <a className="text-white no-underline">
                      <span>For Job Seeker</span>
                    </a>
                  </Link>
                </>

                <div className="flex gap-tsm">
                  <RhButton
                    onClick={onLoginAsEmployerHandler}
                    className="border-white text-white"
                    layout="outline"
                  >
                    Login
                  </RhButton>

                  <Link href="/request-demo" passHref>
                    <RhButton className="btn-bg">Book A Demo </RhButton>
                  </Link>
                </div>
              </div>

              <RhIcon
                icon={"charm:menu-hamburger"}
                className="cursor-pointer text-2xl text-white sm:block lg:hidden"
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              />
            </div>
          </div>
        </nav>

        <div className="relative z-[7]">
          <div className="grid grid-cols-1 gap-tsm py-tsm pl-tsm md:pl-10 md:pt-10 lg:mt-[70px] lg:grid-cols-2 lg:pl-20 lg:pt-20">
            <div className="flex items-start justify-center">
              <div className="flex flex-col gap-tsm">
                <div className="mt-t3xl flex flex-col gap-tsm lg:mb-tmini lg:mt-0">
                  <h3 className="text-gradient-1">#ElevateWithZelevate</h3>
                  <h1 className="text-white">
                    In {new Date().getFullYear()}, forget resumes and Hire the{" "}
                    <span
                      className={classNames("mr-tnano", {
                        "text-gradient-1": isHighlighted,
                      })}
                    >
                      Perfect Candidate
                    </span>
                    with
                    <span
                      className={classNames("mr-tnano", {
                        "text-gradient-1": isHighlighted,
                      })}
                    >
                      {" "}
                      Zelevate!
                    </span>
                  </h1>
                </div>

                <h4 className="mb-tm2 mr-tsm font-normal text-secondary-200 md:mr-tm2">
                  {`Hire Smarter and Faster with the all new and exclusive Zelevate STEM Process`}
                </h4>

                {/* input */}
                <form
                  onSubmit={onEmployerEmailSubmit}
                  className="mr-tsm flex flex-col items-start gap-tsm md:mr-tm2 md:flex-row lg:mr-0"
                >
                  <RhInput
                    name="email"
                    className="w-full md:flex-1"
                    placeholder="Enter Email ID"
                  ></RhInput>
                  <RhButton type="submit" className="btn-bg w-full md:w-auto">
                    Let’s Get Started!
                  </RhButton>
                </form>

                {/* in out */}
                <div className="hidden lg:block">
                  <div className="my-t4xl grid grid-cols-3 justify-between">
                    <div className="flex flex-col">
                      <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                        <CustomIcon icon={iconTwoPesonOutlined} />
                      </p>

                      <h2 className="pt-tnano text-white">10,000+</h2>
                      <div className="flex flex-col">
                        <p className="text-white">Interviewed </p>
                        <p className="text-white">Candidates </p>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                        <CustomIcon icon={iconDocumentOutlined} />
                      </p>

                      <h2 className="pt-tnano text-white">300+</h2>

                      <div className="flex flex-col">
                        <p className="text-white">Offer Letters </p>
                        <p className="text-white">Issued </p>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                        <CustomIcon icon={iconBriefcase} />
                      </p>

                      <h2 className="pt-tnano text-white">50+</h2>

                      <div className="flex flex-col">
                        <p className="text-white">Happy </p>
                        <p className="text-white">Employers </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <motion.div
              initial={{ z: -50, x: -10, y: 10, opacity: 0 }}
              animate={{ z: 0, x: 0, y: 0, opacity: 1 }}
              transition={{
                type: "just",
                delay: 0.7,
              }}
              className="flex h-fit"
              onAnimationComplete={onCandidateCardAnimationComplete}
            >
              <h2 className="text-gradient-1 relative whitespace-nowrap pr-tsm">
                <span>Zelevate Interviewed</span>
              </h2>
              {/* </div> */}
              <div className="relative h-[60vh]">
                {isCardAnimationComplete && (
                  <Player
                    background="transparent"
                    speed={1}
                    autoplay
                    src={animationStartHereArrow}
                    keepLastFrame
                    style={{ width: "200px" }}
                    className="absolute -left-[148px] -top-[4%] -rotate-6"
                  />
                )}
                <img
                  src={employerCandidateCard}
                  className="h-full object-cover object-left"
                ></img>
              </div>
              {/* </div> */}
            </motion.div>
          </div>
          {/* Counters */}

          {/* COUNTERS FOR MOCILE SCREEN */}
          <div className="p-tsm md:px-10 lg:hidden lg:px-20">
            <div className="my-t4xl grid grid-cols-3 justify-between">
              <div className="flex flex-col">
                <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                  <CustomIcon icon={iconTwoPesonOutlined} />
                </p>

                <h2 className="pt-tnano text-white">6000+</h2>
                <div className="flex flex-col">
                  <p className="text-white">Pre-Interviewed </p>
                  <p className="text-white">Candidates </p>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                  <CustomIcon icon={iconDocumentOutlined} />
                </p>

                <h2 className="pt-tnano text-white">300+</h2>

                <div className="flex flex-col">
                  <p className="text-white">Offer Letters </p>
                  <p className="text-white">Issued </p>
                </div>
              </div>

              <div className="flex flex-col">
                <p className="bg-greadient-1 flex h-txl w-txl items-center justify-center rounded-[4px]">
                  <CustomIcon icon={iconBriefcase} />
                </p>

                <h2 className="pt-tnano text-white">50+</h2>

                <div className="flex flex-col">
                  <p className="text-white">Happy </p>
                  <p className="text-white">Employers </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute -left-2 -top-2 overflow-hidden">
          <Image
            src={vectorSquares}
            layout="fixed"
            width={"2233"}
            height={"374"}
          ></Image>
        </div>

        <div className="footer-gradient-1 absolute bottom-0 right-0 h-48 w-48 rounded-full"></div>
        <div className="footer-gradient-1 absolute bottom-0 left-0 h-48 w-48 rounded-full"></div>
      </div>
    </>
  );
};

export default Banner;
