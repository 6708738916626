import analytics from "./endpoints/analytics";
import assessmentBuilder from "./endpoints/assessment-builder";
import assessments from "./endpoints/assessments";
import auth from "./endpoints/auth";
import candidate from "./endpoints/candidate";
import company from "./endpoints/company";
import interviewer from "./endpoints/interviewer";
import recruiter from "./endpoints/recruiter";

export default {
  auth,
  interviewer,
  candidate,
  recruiter,
  assessments,
  company,
  assessmentBuilder,
  analytics,
};
