import Head from "next/head";

import Home from "@/views/employer";
import React from "react";

const HomePage = () => {
  return (
    <>
      <Head>
        <title>Employer | Zelevate</title>
        <meta
          name="description"
          content="Recruit Top-Tier Developers in India with 3+ Years of Experience"
        />
      </Head>
      <Home />
    </>
  );
};

export default HomePage;

export async function getStaticProps() {
  return {
    props: {},
  };
}
